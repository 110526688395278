import { useState } from "react";

type Props = {
  domain: string;
};

const Feature = ({ domain }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [word, setWord] = useState("");
  const [meaning, setMeaning] = useState<any>(null);

  const searchMeaning = () => {
    if (word) {
      try {
        setIsLoading(true);
        setMeaning(null);
        fetch(`https://api.dictionaryapi.dev/api/v2/entries/en/${word}`)
          .then((response) => response.json())
          .then((data) => {
            if (data[0]) {
              setMeaning(data[0]);
            }
          })
          .finally(() => {
            setIsLoading(false);
          });
      } catch (err) {
        console.log(err);
        setIsLoading(false);
      }
    }
  };
  return (
    <>
      <h1 className="-mt-48 text-lg font-extrabold mb-4 text-white">
        {domain.replace(".xyz", "")}
      </h1>
      <div className="mb-24 bg-white rounded-md shadow-xl overflow-hidden flex items-center justify-center min-h-48 p-5 w-96 max-w-full">
        <div className="flex flex-col gap-2">
          Write a word and get the definition
          <form
            onSubmit={(e) => {
              e.preventDefault();
              searchMeaning();
            }}
            className="flex flex-col gap-2"
          >
            <input
              type="text"
              name="text"
              className="border border-gray-300 rounded-md px-2 py-1"
              value={word}
              onChange={(e) => setWord(e.target.value)}
            />
            <div className="flex items-center justify-center gap-2">
              <button
                className="bg-gray-100 block rounded-md w-fit min-w-[6rem] text-center px-5 py-1"
                onClick={() => searchMeaning()}
              >
                {isLoading ? (
                  <img
                    src="/tail-spin.svg"
                    alt="loading..."
                    className="h-5 w-5 mx-auto"
                  />
                ) : (
                  "Search"
                )}
              </button>
              {meaning && (
                <button
                  className="bg-gray-100 block rounded-md w-fit min-w-[6rem] text-center px-5 py-1"
                  type="button"
                  onClick={() => setMeaning(null)}
                >
                  Clear
                </button>
              )}
            </div>
          </form>
          {!isLoading && meaning && (
            <div>
              <div>
                <h1 className="text-lg font-bold">{meaning.word}</h1>
                <p className="text-sm font-light">{meaning.phonetic}</p>
              </div>
              <div>
                {meaning.meanings.map((m: any) => (
                  <div>
                    <h1 className="text-sm font-bold">{m.partOfSpeech}</h1>
                    <ul>
                      {m.definitions.slice(0, 1).map((d: any) => (
                        <li className="text-sm font-light">{d.definition}</li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Feature;
